<template>
  <section class="section" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="title-box">
            <p class="title-alt">KURLABS CDS</p>
            <h3 class="fadeIn animated wow" data-wow-delay=".1s">{{$t("Benefits")}}</h3>
            <div class="border"></div>
          </div>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-lg-3">
          <div class="service-item animated fadeInLeft wow" data-wow-delay=".1s">
            <h2 class="mb-4">
              <i class="zmdi zmdi-collection-item-1 text-colored"></i>
            </h2>
            <div class="service-detail">
              <p>{{$t("It reduces the cost, time and risk of making changes by allowing more updates to applications in testing and production.")}}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="service-item animated fadeInDown wow" data-wow-delay=".3s">
            <h2 class="mb-4">
              <i class="zmdi zmdi-collection-item-2 text-colored"></i>
            </h2>
            <div class="service-detail">
              <p>{{$t("Construction, testing and software release in shorter times and more frequently.")}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item animated fadeInRight wow" data-wow-delay=".5s">
            <h2 class="mb-4">
              <i class="zmdi zmdi-collection-item-3 text-colored"></i>
            </h2>
            <div class="service-detail">
              <p>{{$t("A simple and repeatable deployment process for continuous delivery.")}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item animated fadeInRight wow" data-wow-delay=".5s">
            <h2 class="mb-4">
              <i class="zmdi zmdi-collection-item-4 text-colored"></i>
            </h2>
            <div class="service-detail">
              <p>{{$t("Reduction of service downtime between new version deployments.")}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Benefits"
};
</script>





